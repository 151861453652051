import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import storage from './utils/storage'
import mixins from './mixins'
import bus from './utils/bus'
import api from './api'
import dayjs from 'dayjs'
import { DEV_MODE } from './utils/constants.js'
import './utils/components.js'
import './utils/filters.js'

Vue.mixin(mixins)

Vue.prototype.$bus = bus
Vue.prototype.$storage = storage
Vue.prototype.$api = api
Vue.prototype.$dayjs = dayjs

const loading = {
	timer: null,
	show(delay = 15000) {
		store.dispatch('setLoading', true)
		this.timer && clearTimeout(this.timer)
		this.timer = setTimeout(this.close, delay)
	},
	close() {
		store.dispatch('setLoading', false)
		this.timer && clearTimeout(this.timer)
	}
}

Vue.prototype.$loading = (delay) => {
	loading.show(delay)
}
Vue.prototype.$loadend = () => {
	loading.close()
}

const token = storage.get('token') || false
storage.set('token', token)
store.dispatch('setToken', token)

var hiddentime = 0
window.addEventListener('visibilitychange', () => {
	const activate = !document.hidden
	const now = Date.now() / 1000
	if (!activate) {
		hiddentime = now
	} else if (hiddentime > 0 && now - hiddentime > 60) {
		ws.reInit()
		if (hiddentime > 300) {
			bus.$emit('refresh')
		}
		hiddentime = 0
	}
})

if (!DEV_MODE) {
	window['console']['log'] = function () { };
	window['console']['info'] = function () { };
}

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
