<template>
    <div class="player-container">
        <div id="dplayer" class="player" ref="dplayer"></div>
        <div v-if="player.status > 0" :class="['status', 'status-' + player.status]"></div>
    </div>
</template>
<script>
import dayjs from 'dayjs';
import { PLAYER_STATUS } from '@/utils/constants.js';

var reinitTimer = null;
var flvPlayer = null

export default {
    name: 'wsPlayer',
    components: {},
    data() {
        return {
            player: {
                url: '',
                instance: null,
                tryCount: 20,
                status: PLAYER_STATUS.LOADING
            },
            live: {
                url: '',
                poster: ''
            }
        };
    },
    methods: {
        initPlayer(options = {}) {
            if (reinitTimer) {
                clearTimeout(reinitTimer);
            }
            if (this.player.instance) {
                try {
                    this.player.instance.destroy();
                } catch (e) { }
            }
            this.live.url = options.url || 'https://cdn.hdzhayouji.com/videoZelda%20TIME.mp4'
            this.live.poster = options.poster || 'https://sta.ldres.co/file/common/20231011/90e45e1f5ae2af159a4ecb1c487f145a.jpg'
            this.player.tryCount = this.match_state == -1 ? 5 : 15
            this.player.status = undefined;
            if (/\.m3u8|\.flv|.mp4/i.test(this.live.url)) {
                this.initLivePlayer();
            } else {
                this.player.status = PLAYER_STATUS.NOSIGN;
            }
        },

        initLivePlayer() {
            if (this.player.instance) {
                try {
                    this.player.instance.destroy();
                } catch (e) { }
            }

            const config = {
                container: document.getElementById('dplayer'),
                live: /\.m3u8|\.flv/i.test(this.live.url),
                autoplay: false,
                lang: 'zh-cn',
                contextmenu: [],
                video: {
                    url: this.live.url,
                    type: 'auto',
                    pic: this.live.poster || require('../assets/img/default_poster.png'),
                }
            }
            console.info(/safari/i.test(navigator.userAgent), this.live.url)
            if (/\.flv/i.test(this.live.url)) {
                const vm = this
                config.video = {
                    url: this.live.url,
                    type: 'customFlv',
                    customType: {
                        customFlv: (video) => {
                            if (flvPlayer) {
                                try {
                                    flvPlayer.destroy()
                                    flvPlayer = null
                                } catch (e) { }
                            }
                            flvPlayer = flvjs.createPlayer({
                                type: 'flv',
                                url: video.src,
                            });
                            flvPlayer.on(flvjs.Events.ERROR, (e) => {
                                console.info('player.flvjs.error', e)
                                vm.onPlayerError(e)
                            })
                            flvPlayer.attachMediaElement(video);
                            flvPlayer.load();
                        },
                    },
                }
            }

            console.info('player.config', config)
            const player = new DPlayer(config);
            player.on('playing', (e) => {
                console.info('player.playing', e)
                this.player.tryCount = 15;
                this.player.status = PLAYER_STATUS.PLAYING;
            })
            player.on('canplay', (e) => {
                console.info('player.canplay', e)
                this.player.status = PLAYER_STATUS.LOADING
                player.play()
            })
            player.on('error', (e) => {
                this.onPlayerError(e)
            })
            player.on('ended', (e) => {
                this.onPlayerError(e)
            })
            console.info('player.instance', player)
            this.player.instance = player;
        },
        onPlayerError(e) {
            console.info('player.error', e)
            const tryCount = --this.player.tryCount;
            let delay = 0;
            if (tryCount > 10) {
                delay = 500;
            } else if (tryCount > 5) {
                this.player.status = PLAYER_STATUS.ERROR;
                delay = 2000;
            } else if (tryCount > 0) {
                this.player.status = this.match_state == -1 ? PLAYER_STATUS.ENDED : PLAYER_STATUS.ERROR;
                delay = 20000;
            } else {
                this.player.status = this.match_state == -1 ? PLAYER_STATUS.ENDED : PLAYER_STATUS.ERROR;
                delay = 60000;
            }
            if (this.match_start - dayjs().unix() > 60) {
                this.player.status = PLAYER_STATUS.NOSTART
                delay = 120000;
            }
            console.info('player.reinitTimer', tryCount, delay)
            reinitTimer && clearTimeout(reinitTimer)
            reinitTimer = setTimeout(() => {
                this.initLivePlayer(this.live);
            }, delay);
        }
    }
};
</script>
<style lang="less">
.player-container,
.dplayer {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: #2b2b2b;

    .player {
        z-index: 1;
    }

    .status {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        // bottom: 50px;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-color: #2b2b2b;

        &.status-1 {
            background-image: url(../assets/img/state_error.png);
            background-size: 204px 234px;
        }

        &.status-2 {
            background-image: url(../assets/img/state_0.png);
            background-size: 204px 234px;
        }

        &.status-3 {
            background-image: url(../assets/img/state_error.png);
            background-size: 204px 234px;
        }

        &.status-4 {
            background-image: url(../assets/img/state_-1.png);
            background-size: 204px 234px;
        }

        &.status-5 {
            background-image: url(../assets/img/state_2048.png);
            background-size: 204px 234px;
        }

        &.status-6 {
            background-color: transparent;
            background-image: url(../assets/img/loading.gif);
            background-position: 40px 40px;
            background-size: 40px 40px;
        }

        &.status-7 {
            background-color: transparent;
            background-image: url(../assets/img/state_1024.png);
            background-position: center center;
            background-size: 340px 190px;
        }
    }
}

.player-mute-button {
    float: right;
    margin-right: 15px;
    margin-top: 12px;
    color: #ffffff;
    cursor: pointer;
}

.dplayer-menu {
    display: none !important;
}
</style>