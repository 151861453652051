<template>
	<div class="page-index">

		<ws-banner :banners="lives"></ws-banner>

		<div class="wrapper">
			<transition name="fade">
				<div class="match" v-if="lives.length > 0">
					<div class="match-header">
						<img class="icon" src="../assets/img/icon-live.png" />
						<div class="title">正在热播</div>
						<span class="line"></span>
						<a href="/lives/1">查看更多</a>
					</div>
					<div class="match-body">
						<ws-match-item v-for="(item, index) in lives" :key="index" :item="item"></ws-match-item>
					</div>
				</div>
			</transition>
			<transition name="fade">
				<div class="match" v-if="hot_anchor.length > 0">
					<div class="match-header">
						<img class="icon" src="../assets/img/icon-anchor.png" />
						<div class="title">热门主播</div>
						<span class="line"></span>
					</div>
					<div class="match-swiper">
						<div class="swiper">
							<swiper :options="swiperOption">
								<swiper-slide class="swiper-slide" v-for="item in hot_anchor" :key="item.member_id">
									<a :href="`/detail/${item.member_id}`" target="_blank" class="swiper-item">
										<div class="avatar"><img :src="item.face" @error="onAvatarError" /></div>
										<span class="name">{{ item.nickname }}</span>
									</a>
								</swiper-slide>
								<div class="swiper-button-prev" slot="button-prev"></div>
								<div class="swiper-button-next" slot="button-next"></div>
							</swiper>
						</div>
					</div>
				</div>
			</transition>
			<transition name="fade">
				<div class="match" v-if="football.length > 0">
					<div class="match-header">
						<img class="icon" src="../assets/img/icon-football.png" />
						<div class="title">足球直播</div>
						<span class="line"></span>
						<a href="/lives/2">查看更多</a>
					</div>
					<div class="match-body"><ws-match-item v-for="(item, index) in football" :key="index"
							:item="item"></ws-match-item></div>
				</div>
			</transition>
			<transition name="fade">
				<div class="match" v-if="basketball.length > 0">
					<div class="match-header">
						<img class="icon" src="../assets/img/icon-basketball.png" />
						<div class="title">篮球直播</div>
						<span class="line"></span>
						<a href="/lives/3">查看更多</a>
					</div>
					<div class="match-body"><ws-match-item v-for="(item, index) in basketball" :key="index"
							:item="item"></ws-match-item></div>
				</div>
			</transition>
			<transition name="fade">
				<div class="match" v-if="synthesis.length > 0">
					<div class="match-header">
						<img class="icon" src="../assets/img/icon-comprehensive.png" />
						<div class="title">综合直播</div>
						<span class="line"></span>
						<a href="/lives/99">查看更多</a>
					</div>
					<div class="match-body"><ws-match-item v-for="(item, index) in synthesis" :key="index"
							:item="item"></ws-match-item></div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'
import wsBanner from '@/components/wsBanner.vue';
import wsMatchItem from '@/components/wsMatchItem.vue';

export default {
	name: 'Home',
	components: {
		wsBanner,
		wsMatchItem
	},
	data() {
		return {
			hot_anchor: [],
			basketball: [],
			football: [],
			synthesis: []
		};
	},
	computed: {
		lives() {
			return this.$store.state.lives.map(this.makeMatchItem)
		}
	},
	async mounted() {
		this.$loading()
		// const day = dayjs().startOf('m').unix()
		// const res = await this.$api.get('/live_types.json', { params: { v: day }, baseURL: '/json' });
		setTimeout(() => {
			this.$loadend();
		}, 1000);
	},
	beforeDestroy() {
		this.$bus.$off('refresh', this.getHomeData);
	},
	methods: {
		async getHomeData() {
			const response = await this.$api.get('/web/index');
			if (response && response.code == 200) {
				const { banner = [], hot = [], hot_anchor = [], football, basketball, synthesis } = response.data;
				this.banners = banner.map(this.makeMatchItem);
				this.hot = hot.map(this.makeMatchItem);
				this.hot_anchor = hot_anchor;
				this.football = football.map(this.makeMatchItem);
				this.basketball = basketball.map(this.makeMatchItem);
				this.synthesis = synthesis.map(this.makeMatchItem);
				this.$nextTick(() => {
					this.$loadend();
				});
			}
		}
	}
};
</script>
