<template>
	<div class="carousel">
		<div class="wrapper">
			<div class="content">
				<div style="width: 954px; height: 600px"><ws-player ref="player"></ws-player></div>
				<div class="mask"><a class="entry" href="javascript:;" @click="onEnter">进入直播间</a></div>
			</div>
			<div class="side">
				<div :class="['side-item', bannerIndex == index ? 'active' : '']" v-for="(banner, index) in banners"
					:key="index" @click="changeBanner(index)">
					<img :src="banner.cover" @error="onCoverError" />
					<div>{{ banner.title }}</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import wsPlayer from '@/components/wsPlayer.vue';
export default {
	name: 'wsBanner',
	components: { wsPlayer },
	props: {
		banners: {
			type: Array,
			default: () => ([])
		}
	},
	data() {
		return {
			bannerIndex: 0
		}
	},
	computed: {

	},
	methods: {
		changeBanner(index) {
			if (this.banners.length > index) {
				const banner = this.banners[index];
				if (banner) {
					this.bannerIndex = index;
					this.$refs.player.initPlayer(banner);
				}
			}
		},
		onEnter() {
			if (this.banners.length > this.bannerIndex) {
				const banner = this.banners[this.bannerIndex];
				this.$router.push(banner.href);
			}
		}
	},
	mounted() {
		if (this.banners.length) {
			this.changeBanner(0)
		}
	},
	watch: {
		banners() {
			if (this.banners.length) {
				this.changeBanner(0)
			}
		}
	}
};
</script>

<style lang="less">
.carousel {
	background: url('../assets/img/v2-8ecc801ffe7f6c525c28c1de27ea068d_1440w.webp') repeat;
	background-size: auto 600px;
}
</style>
