<template>
	<div class="footer">
		<div class="wrapper">
			<div class="links">
				<router-link to="/aboutus" class="link">关于4E直播</router-link>
				<span class="divider"> | </span>
				<router-link to="/disclaimer" class="link">免责申明</router-link>
			</div>
			<div class="copyright">
				<span>粤ICP 备19005704号-1</span>
				<span class="divider"> | </span>
				<span> Copyight©2023 4E直播.Rights Reserved </span>
			</div>
		</div>

	</div>
</template>

<script>
export default {
	name: 'wsFooter'
};
</script>

<style lang="less"></style>
