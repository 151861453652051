<template>
	<header class="header">
		<div class="wrapper">
			<a href="/" class="logo"><img src="@/assets/img/logo.png" /></a>
			<nav class="menu">
				<a :class="[routeName == 'home' ? 'active' : '']" href="/">首页</a>
				<a :class="[routeName == 'lives' ? 'active' : '']" href="/lives/1">直播</a>
				<a href="javascript:;" @click="onAppointment">预约</a>
			</nav>

			<div style="flex: 1;"></div>

			<div class="form">
				<span>热门主播</span>
				<img class="icon-search" src="../assets/img/icon-search.png" />
			</div>

			<div class="navigates">
				<div class="navigate" @click="onAppointment">
					<img class="icon-search" src="../assets/img/icon-download.png" />
					<span>下载</span>
				</div>
				<div class="navigate" @click="onAppointment">
					<img class="icon-search" src="../assets/img/icon-history.png" />
					<span>历史</span>
				</div>
			</div>

			<nav class="user" v-if="minfo.uid">
				<a href="/anchor/" class="login" target="_blank" v-if="minfo && minfo.role && [3].includes(minfo.role)"
					style="margin-right:20px">开始直播</a>
				<div class="userinfo">
					<img class="avatar" :src="minfo.avatar" :alt="minfo.nickname" @error="onAvatarError" />
					<span>{{ minfo.nickname }}</span>
					<div class="userinfo-popover">
						<div class="userinfo-popover-content">
							<div class="info">
								<img class="avatar" :src="minfo.avatar" :alt="minfo.nickname" @error="onAvatarError" />
								<span class="name">{{ minfo.nickname }}</span>
								<a href="javascript:;" class="button" @click="onLogout">退出登录</a>
							</div>
							<div class="sign">{{ minfo.sign }}</div>
							<div class="tabs">
								<router-link to="/user/info" class="tabs-item icon-user">个人中心</router-link>
								<router-link to="/user/follow" class="tabs-item icon-follow">我的关注</router-link>
								<router-link to="/user/appointment" class="tabs-item icon-appointment">我的预约</router-link>
								<router-link to="/user/feedback" class="tabs-item icon-feedback">我的反馈</router-link>
							</div>
						</div>
					</div>
				</div>
			</nav>
			<nav class="user" v-else>
				<img class="icon-auth" src="../assets/img/icon-auth.png" />
				<a href="javascript:;" class="login" @click="toLogin">登录</a>
				<span>|</span>
				<a href="javascript:;" class="register" @click="toRegister">注册</a>
			</nav>
		</div>
		<ws-authorize :type.sync="modal.type" v-model="modal.visible"></ws-authorize>
	</header>
</template>

<script>
import { mapState } from 'vuex';
import wsAuthorize from '@/components/wsAuthorize.vue';
import { AUTHORIZE_TYPE } from '@/utils/constants.js';
// import { Modal } from 'ant-design-vue';

export default {
	name: 'wsHeader',
	components: {
		wsAuthorize
	},
	data() {
		return {
			AUTHORIZE_TYPE,
			modal: {
				type: AUTHORIZE_TYPE.LOGIN_PASSWORD,
				visible: false
			}
		};
	},
	computed: {
		...mapState(['minfo']),
		routeName() {
			return this.$route.name.toLowerCase();
		}
	},
	created() {
		this.$bus.$on('login', this.toLogin);
	},
	methods: {
		onAppointment() {
			if (this.minfo.uid) {
				this.$message.info('敬请期待')
			} else {
				this.toLogin()
			}
		},
		toLogin() {
			this.modal.type = AUTHORIZE_TYPE.LOGIN_PASSWORD;
			this.modal.visible = true;
		},
		toRegister() {
			this.modal.type = AUTHORIZE_TYPE.REGISTER;
			this.modal.visible = true;
		},
		onLogout() {
			this.$modal.confirm({
				title: '确定要退出登录吗?',
				okType: 'danger',
				okText: '退出',
				cancelText: '取消',
				onOk: async () => {
					await this.$api.post('/web/login_out');
					this.$storage.clear();
					location.href = '/';
				}
			});
		}
	}
};
</script>

<style lang="less">
.navigates {
	display: flex;
	align-items: center;

	.navigate {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: #DCDCDC;
		font-size: 12px;
		padding: 0 14px;

		img {
			width: 20px;
			height: 20px;
			display: block;
		}
	}
}
</style>
