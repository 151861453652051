<template>
	<div class="wrapper">
		<div class="main-section">
			<div class="content">
				<div class="auchor">
					<img class="avatar" :src="room.anchor.icon" @error="onAvatarError" />
					<div class="info">
						<div class="title">
							{{ room.title }}
						</div>
						<div class="meta">
							<span class="nickname">{{ room.anchor.nickName }}</span>
							<span class="viewer">{{ room.viewCount || 0 | kindlyNumber }}</span>
							<span style="flex:1"></span>
						</div>
					</div>
					<div class="extrainfo" v-if="minfo.uid != room.uid">
						<a :class="['follow', room.is_follow == 1 ? 'active' : '']" href="javascript:;"
							@click="onFollow">关注</a>
						<a-popover placement="bottom" v-if="!!room.qrcode">
							<template slot="content">
								<div class="popover-qrcode">
									<img :src="room.qrcode" />
									<span>
										扫一扫
										<br />
										加主播好友
									</span>
								</div>
							</template>
							<img class="qrcode" src="../assets/img/icon-qrcode.png" />
						</a-popover>
					</div>
				</div>
				<div class="player-wrapper">
					<ws-player ref="player" />
				</div>
			</div>
			<div class="aside">
				<ws-chatroom :match="room" :anchor_uid="room.roomNum" ref="chatroom"></ws-chatroom>
			</div>
		</div>

		<div class="section" v-if="schedules.length > 0">
			<div class="section-header">主播日程</div>
			<div class="section-swiper">
				<div class="schedules">
					<div class="swiper-container">
						<div class="swiper-wrapper">
							<div class="swiper-slide schedule-item" v-for="(item, index) in schedules" :key="index">
								<div class="meta">
									<img v-if="item.tournament_type == 1" src="../assets/img/icon-schedule-1.png" />
									<img v-else-if="item.tournament_type == 2" src="../assets/img/icon-schedule-2.png" />
									<span class="league">{{ item.league_name_zh }}</span>
									{{ (item.start_time_int * 1000) | dateFormat('MM-DD HH:mm') }}
								</div>
								<div class="teamname">
									<img :src="item.home_logo" @error="onTeamLogoError($event, item.tournament_type)" />
									<span>{{ item.home_team_zh }}</span>
								</div>
								<div class="teamname">
									<img :src="item.away_logo" @error="onTeamLogoError($event, item.tournament_type)" />
									<span>{{ item.away_team_zh }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="section" v-if="recommends.length > 0">
			<div class="section-header">视频推介</div>
			<div class="section-body">
				<ws-match-item v-for="(item, index) in recommends" :key="index" :item="item"></ws-match-item>
			</div>
		</div>

		<!-- <div class="tips">
			特别声明：
			<br />
			4E直播是一个综合性体育赛事直播平台，我们所有的赛事直播源均由第三方提供，我们不提供任何独家赛事直播视频，不制作任何赛事直播数据。
			<br />
			请各位网友遵守当地国家法律法规，请勿用于非法用途，如有疑问请与我们取得联系。
		</div> -->
	</div>
</template>

<script>
import wsPlayer from '@/components/wsPlayer.vue';
import wsMatchItem from '@/components/wsMatchItem.vue';
import wsChatroom from '@/components/wsChatroom.vue';
import { mapState } from 'vuex';
export default {
	name: 'Home',
	components: {
		wsMatchItem,
		wsPlayer,
		wsChatroom,
	},
	data() {
		return {
			match: {},
			more: [],

			schedules: [],
			recommends: []
		};
	},
	computed: {
		...mapState(['minfo']),
		room() {
			const item = this.$store.state.lives.find(v => v.roomNum == this.$route.params.uid) || { anchor: {} }
			return Object.assign(item, { room_status: 0, room_notice: '尊重市场逻辑,专注实战效果' })
		}
	},
	// beforeRouteUpdate(to, from, next) {
	// 	this.$loading();
	// 	this.getLiveInfo(to.params);
	// 	next();
	// },
	// beforeRouteEnter(to, from, next) {
	// 	next(vm => {
	// 		vm.$loading();
	// 		vm.getLiveInfo(to.params);
	// 	});
	// },
	created() {
		this.$bus.$on('refresh', () => {
			location.reload();
		});
	},
	mounted() {
		this.$refs.player.initPlayer({})
	},
	methods: {

	}
};
</script>

<style lang="less">
@import url('~@/assets/less/live.less');

.popover-qrcode {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 12px;
	color: #333333;
	text-align: center;

	img {
		width: 84px;
		height: 84px;
		margin-bottom: 10px;
	}
}
</style>
