import Vue from 'vue'
import {
    Modal,
    Row,
    Col,
    Form,
    FormModel,
    Input,
    Checkbox,
    Popconfirm,
    Button,
    Avatar,
    Upload,
    Popover,
    message,
    Pagination,
    Dropdown,
    Menu,
    ConfigProvider
} from 'ant-design-vue';

Vue.config.productionTip = false
Vue.use(Modal)
Vue.use(Row)
Vue.use(Col)
Vue.use(Form)
Vue.use(FormModel)
Vue.use(Input)
Vue.use(Checkbox)
Vue.use(Popconfirm)
Vue.use(Button)
Vue.use(Avatar)
Vue.use(Upload)
Vue.use(Popover)
Vue.use(Pagination)
Vue.use(Dropdown)
Vue.use(Menu)
Vue.use(ConfigProvider)


Vue.prototype.$message = message
Vue.prototype.$modal = Modal