import Vue from 'vue'
import Vuex from 'vuex'
import api from '../api'
Vue.use(Vuex)

const TYPES = {
	'TOKEN': 'TOKEN',
	'USER_INFO': 'USER_INFO',
	'MEMBER_INFO': 'MEMBER_INFO',
	'APPOINTMENTS': 'APPOINTMENTS',
	'LOADING': 'LOADING',
	'DOWNLOAD_URL': 'DOWNLOAD_URL'
}
export default new Vuex.Store({
	state: {
		token: '',
		minfo: {},
		appointments: [],

		loading: false,

		lives: [
			{
				"anchor": {
					"uid": 722384,
					"nickName": "苏妃助理",
					"icon": "https://sta.ldres.co/file/head/20231011/080c974432dde959e928aecca8dde264.jpg",
					"gender": 3,
					"birthday": 0,
					"userType": 2,
					"grow": 0,
					"score": 0,
					"growDto": {
						"id": 1,
						"name": "Lv.1",
						"minGrom": 0,
						"nextMinGrom": 100
					},
					"createTime": 1697006148000,
					"cutOutIcon": "https://sta.ldres.co/file/head/20231011/080c974432dde959e928aecca8dde264_ss300.jpg"
				},
				"roomNum": "722384",
				"title": "回放 什么是白皮书？",
				"viewCount": 1014,
				"markType": 0,
				"hd": 2,
				"cover": "https://sta.ldres.co/file/common/20231011/90e45e1f5ae2af159a4ecb1c487f145a.jpg",
				"notice": "4E的崛起之道",
				"detail": "4E的崛起之道",
				"liveStatus": 1,
				"liveType": 13,
				"liveTypeParent": 1,
				"focusCount": 111982,
				"contact": "",
				"coverType": 1,
				"customCover": "/common/20231011/90e45e1f5ae2af159a4ecb1c487f145a.jpg",
				"customCoverUrl": "https://sta.ldres.co/file/common/20231011/90e45e1f5ae2af159a4ecb1c487f145a.jpg",
				"streamType": 7,
				"cutOutCustomCoverUrl": "https://sta.ldres.co/file/common/20231011/90e45e1f5ae2af159a4ecb1c487f145a_wh320.jpg"
			},
			{
				"anchor": {
					"uid": 617546,
					"nickName": "閃妮老師的助理",
					"icon": "https://sta.ldres.co/file/head/20230914/9ac7ae9664cd47f55bd7125d14d7b6aa.jpg",
					"gender": 3,
					"birthday": 0,
					"userType": 2,
					"grow": 0,
					"score": 0,
					"growDto": {
						"id": 1,
						"name": "Lv.1",
						"minGrom": 0,
						"nextMinGrom": 100
					},
					"createTime": 1694675422000,
					"cutOutIcon": "https://sta.ldres.co/file/head/20230914/9ac7ae9664cd47f55bd7125d14d7b6aa_ss300.jpg"
				},
				"roomNum": "617546",
				"title": "【雷達直播】熱衷關心市場活躍者可得100USDT",
				"viewCount": 1014,
				"markType": 0,
				"hd": 2,
				"cover": "https://sta.ldres.co/file/common/20231115/f0b204f145924d7f3ab898fa348c6e6b.jpg",
				"notice": "免費學習交易技巧 _ 財經直播解密 _ 金融投資全攻略",
				"detail": "免費學習交易技巧 _ 財經直播解密 _ 金融投資全攻略",
				"liveStatus": 1,
				"liveType": 13,
				"liveTypeParent": 1,
				"focusCount": 161241,
				"contact": "",
				"coverType": 1,
				"customCover": "/common/20231115/f0b204f145924d7f3ab898fa348c6e6b.jpg",
				"customCoverUrl": "https://sta.ldres.co/file/common/20231115/f0b204f145924d7f3ab898fa348c6e6b.jpg",
				"streamType": 7,
				"cutOutCustomCoverUrl": "https://sta.ldres.co/file/common/20231115/f0b204f145924d7f3ab898fa348c6e6b_wh320.jpg"
			},
			{
				"anchor": {
					"uid": 568948,
					"nickName": "白鹿助理",
					"icon": "https://sta.ldres.co/file/head/20231011/fac735cb00a1044f8909d83e63a31319.jpg",
					"gender": 3,
					"birthday": 0,
					"userType": 2,
					"grow": 0,
					"score": 0,
					"growDto": {
						"id": 1,
						"name": "Lv.1",
						"minGrom": 0,
						"nextMinGrom": 100
					},
					"createTime": 1697005981000,
					"cutOutIcon": "https://sta.ldres.co/file/head/20231011/fac735cb00a1044f8909d83e63a31319_ss300.jpg"
				},
				"roomNum": "568948",
				"title": "回放 歷久不衰的交易策略真的這麼牛?",
				"viewCount": 1013,
				"markType": 0,
				"hd": 2,
				"cover": "https://sta.ldres.co/file/common/20231011/73748c56a4e6c694b3befced5394cd29.jpg",
				"notice": "大家好我是白鹿 曾经的币圈小白变成可以自己打单的讲师历程",
				"detail": "大家好我是白鹿  曾经的币圈小白变成可以自己打单的讲师历程",
				"liveStatus": 1,
				"liveType": 11,
				"liveTypeParent": 4,
				"focusCount": 143407,
				"contact": "",
				"coverType": 1,
				"customCover": "/common/20231011/73748c56a4e6c694b3befced5394cd29.jpg",
				"customCoverUrl": "https://sta.ldres.co/file/common/20231011/73748c56a4e6c694b3befced5394cd29.jpg",
				"streamType": 7,
				"cutOutCustomCoverUrl": "https://sta.ldres.co/file/common/20231011/73748c56a4e6c694b3befced5394cd29_wh320.jpg"
			},
			{
				"anchor": {
					"uid": 724196,
					"nickName": "小巴飞助理",
					"icon": "https://sta.ldres.co/file/head/20231027/f0d8550bd58cf45dbd3832c7a51aaa87.png",
					"gender": 3,
					"birthday": 0,
					"userType": 2,
					"grow": 0,
					"score": 0,
					"growDto": {
						"id": 1,
						"name": "Lv.1",
						"minGrom": 0,
						"nextMinGrom": 100
					},
					"createTime": 1697099086000,
					"cutOutIcon": "https://sta.ldres.co/file/head/20231027/f0d8550bd58cf45dbd3832c7a51aaa87_ss300.png"
				},
				"roomNum": "724196",
				"title": "回放 香港金融市場",
				"viewCount": 1012,
				"markType": 0,
				"hd": 2,
				"cover": "https://sta.ldres.co/file/common/20231027/b06882474ba2bcf97e996247bd145301.jpg",
				"notice": "市场分析",
				"detail": "市场分析",
				"liveStatus": 1,
				"liveType": 20,
				"liveTypeParent": 5,
				"focusCount": 120631,
				"contact": "",
				"coverType": 1,
				"customCover": "/common/20231027/b06882474ba2bcf97e996247bd145301.jpg",
				"customCoverUrl": "https://sta.ldres.co/file/common/20231027/b06882474ba2bcf97e996247bd145301.jpg",
				"streamType": 7,
				"cutOutCustomCoverUrl": "https://sta.ldres.co/file/common/20231027/b06882474ba2bcf97e996247bd145301_wh320.jpg"
			},
			{
				"anchor": {
					"uid": 604294,
					"nickName": "PETER老师助理",
					"icon": "https://sta.ldres.co/file/head/20230909/8064d15ed13cc425f185d0cb564417ba.png",
					"gender": 3,
					"birthday": 0,
					"userType": 2,
					"grow": 0,
					"score": 0,
					"growDto": {
						"id": 1,
						"name": "Lv.1",
						"minGrom": 0,
						"nextMinGrom": 100
					},
					"createTime": 1693910790000,
					"cutOutIcon": "https://sta.ldres.co/file/head/20230909/8064d15ed13cc425f185d0cb564417ba_ss300.png"
				},
				"roomNum": "604294",
				"title": "回放:机构进场？比特币要发车了吗？",
				"viewCount": 1010,
				"markType": 0,
				"hd": 2,
				"cover": "https://sta.ldres.co/file/common/20230909/b49bebcc2383f37b9a0e3310583a058a.jpg",
				"notice": "PETER老师直播回放",
				"detail": "PETER老师直播回放",
				"liveStatus": 1,
				"liveType": 11,
				"liveTypeParent": 4,
				"focusCount": 141916,
				"contact": "",
				"coverType": 1,
				"customCover": "/common/20230909/b49bebcc2383f37b9a0e3310583a058a.jpg",
				"customCoverUrl": "https://sta.ldres.co/file/common/20230909/b49bebcc2383f37b9a0e3310583a058a.jpg",
				"streamType": 7,
				"cutOutCustomCoverUrl": "https://sta.ldres.co/file/common/20230909/b49bebcc2383f37b9a0e3310583a058a_wh320.jpg"
			}
		],

		iosUrl: "",
		androidUrl: "",
	},
	mutations: {
		[TYPES.TOKEN](state, token) {
			state.token = token
		},
		[TYPES.MEMBER_INFO](state, minfo) {
			state.minfo = minfo
		},
		[TYPES.APPOINTMENTS](state, appointments) {
			state.appointments = appointments
		},
		[TYPES.LOADING](state, loading) {
			state.loading = loading
		},
		[TYPES.DOWNLOAD_URL](state, {
			iosUrl,
			androidUrl
		}) {
			state.iosUrl = iosUrl
			state.androidUrl = androidUrl
		}
	},
	actions: {
		async setToken(context, token = false) {
			context.commit(TYPES.TOKEN, token)
			if (token) {
				context.dispatch('getMemberInfo')
			}
		},
		async getMemberInfo(context) {
			const response = await api.get('/get_user')
			if (response && response.code == 200) {
				context.commit(TYPES.MEMBER_INFO, response.data)
				return response.data
			}
			return Promise.reject(false)
		},
		async setMemberInfo(context, minfo = {}) {
			context.commit(TYPES.MEMBER_INFO, minfo)
		},
		async syncMemberInfo(context) {
			if (context.state.minfo.uid) {
				return context.state.minfo
			}
			return context.dispatch('getMemberInfo')
		},
		async getAppointments(context) {
			const response = await api.get('/web/appointment/list')
			if (response) {
				const appointments = []
				Object.keys(response.data).forEach(date => {
					appointments.push({
						date,
						lists: response.data[date]
					})
				})
				context.commit(TYPES.APPOINTMENTS, appointments)
			}
		},
		async setLoading(context, show) {
			context.commit(TYPES.LOADING, show)
		},
		async getDownloadUrls(context) {
			const response = await api.get('/web/download')
			if (response.code == 200) {
				const {
					android_url,
					ios_url
				} = response.data
				const apk = /03zbb\.com/.test(location.hostname) ? 'https://imim.zsw998.com/01zbb_v1.0.5-20230517_20-46-46.apk' : android_url

				context.commit(TYPES.DOWNLOAD_URL, {
					iosUrl: ios_url,
					androidUrl: apk
				})
			}
			return response.data
		}
	}
})
