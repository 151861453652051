export default {
	data() {
		return {

		}
	},
	methods: {
		onCoverError(e) {
			e.target.src = require('@/assets/img/default_cover.png')
		},
		onAvatarError(e) {
			e.target.src = require('@/assets/img/default_avatar.png')
		},
		makeMatchItem(item) {
			item.href = `/room/${item.roomNum}`
			return item
		}
	}
}
