import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '../views/layout.vue'

Vue.use(VueRouter)

const routes = [{
	path: '',
	name: 'layout',
	component: layout,
	redirect: '/',
	children: [{
		path: '/',
		name: 'Home',
		component: () => import( /* webpackChunkName: "home" */ '../views/Home.vue')
	}, {
		path: '/lives/:type',
		name: 'Lives',
		component: () => import( /* webpackChunkName: "lives" */ '../views/Lives.vue')
	}, {
		path: '/lives',
		redirect: '/lives/1'
	}, {
		path: '/detail/:tournament_type/:tournament_id/:member_id',
		name: 'Live',
		component: () => import( /* webpackChunkName: "live" */ '../views/Live.vue')
	}, {
		path: '/detail/:uid',
		name: 'LiveInfo',
		component: () => import( /* webpackChunkName: "live" */ '../views/Live.vue')
	}, {
		path: '/room/:uid',
		name: 'RoomInfo',
		component: () => import( /* webpackChunkName: "live" */ '../views/Live.vue')
	}, {
		path: '/reply/:id',
		name: 'Reply',
		component: () => import( /* webpackChunkName: "reply" */ '../views/Reply.vue')
	}, {
		path: '/schedules/:type',
		name: 'Schedules',
		component: () => import( /* webpackChunkName: "schedules" */ '../views/Schedules.vue')
	}, {
		path: '/app',
		name: 'App',
		component: () => import( /* webpackChunkName: "appdownload" */ '../views/App.vue')
	}, {
		path: '/aboutus',
		name: 'About',
		component: () => import( /* webpackChunkName: "aboutus" */ '../views/About.vue')
	}, {
		path: '/disclaimer',
		name: 'Disclaimer',
		component: () => import( /* webpackChunkName: "disclaimer" */ '../views/Disclaimer.vue')
	}, {
		path: '/schedules',
		redirect: '/schedules/1'
	}, {
		path: '/user',
		name: 'User',
		component: () => import( /* webpackChunkName: "users" */ '../views/User.vue'),
		redirect: '/user/info',
		children: [{
			path: '/user/info',
			name: 'UserInfo',
			component: () => import( /* webpackChunkName: "userinfo" */
				'../views/UserInfo.vue'),
			meta: {
				title: '我的资料'
			}
		}, {
			path: '/user/follow',
			name: 'UserFollow',
			component: () => import( /* webpackChunkName: "userfollow" */
				'../views/UserFollow.vue'),
			meta: {
				title: '我的关注'
			}
		}, {
			path: '/user/appointment',
			name: 'UserAppointment',
			component: () => import( /* webpackChunkName: "userappointment" */
				'../views/UserAppointment.vue'),
			meta: {
				title: '我的预约'
			}
		}, {
			path: '/user/feedback/create',
			name: 'UserFeedbackCreate',
			component: () => import( /* webpackChunkName: "userfeedbackcreate" */
				'../views/UserFeedbackCreate.vue'),
			meta: {
				title: '我的反馈'
			}
		}, {
			path: '/user/feedback',
			name: 'UserFeedback',
			component: () => import( /* webpackChunkName: "userfeedback" */
				'../views/UserFeedback.vue'),
			meta: {
				title: '我的反馈'
			}
		}]
	}, {
		path: '*',
		redirect: '/'
	}]
}]

const router = new VueRouter({
	mode: 'history',
	scrollBehavior() {
		return {
			x: 0,
			y: 0
		}
	},
	routes
})

router.beforeEach((to, from, next) => {
	if (to.name) {
		document.body.className = 'page-' + to.name.toLowerCase()
	}
	next()
})

export default router
