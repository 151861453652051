<template>
	<div class="wrapper page-lives">
		<div class="match">
			<!-- <div class="match-tabs">
				<router-link :to="`/lives/1`" :class="{ active: query.type == 1 }">全部</router-link>
				<a-popover placement="bottom">
					<template slot="content">
						<ul class="subtabs" v-if="Array.isArray(subtabs[2])">
							<li v-for="item in subtabs[2]" :key="item.id">
								<router-link :to="`/lives/2?league_id=${item.id}`" :style="{
									backgroundImage: `url(${item.icon})`
								}">
									{{ item.text }}
								</router-link>
							</li>
						</ul>
					</template>
					<router-link to="/lives/2" :class="{ active: query.type == 2 }">
						足球
						<i></i>
					</router-link>
				</a-popover>

				<a-popover placement="bottom">
					<template slot="content">
						<ul class="subtabs" v-if="Array.isArray(subtabs[3])">
							<li v-for="item in subtabs[3]" :key="item.id">
								<router-link :to="`/lives/3?league_id=${item.id}`" :style="{
									backgroundImage: `url(${item.icon})`
								}">
									{{ item.text }}
								</router-link>
							</li>
						</ul>
					</template>
					<router-link to="/lives/3" :class="{ active: query.type == 3 }">
						篮球
						<i></i>
					</router-link>
				</a-popover>

				<a-popover placement="bottom">
					<template slot="content">
						<ul class="subtabs" v-if="Array.isArray(subtabs[99])">
							<li v-for="item in subtabs[99]" :key="item.id">
								<router-link :to="`/lives/${item.id}`" :style="{
									backgroundImage: `url(${item.icon})`
								}">
									{{ item.text }}
								</router-link>
							</li>
						</ul>
					</template>
					<router-link to="/lives/99" :class="{ active: query.type > 3 }">
						综合
						<i></i>
					</router-link>
				</a-popover>
			</div> -->

			<div class="match-body" v-if="lives.length > 0">
				<ws-match-item v-for="(item, index) in lives" :key="index" :item="item" />
			</div>
			<div class="match-empty" v-else></div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import wsMatchItem from '@/components/wsMatchItem';


export default {
	name: 'Home',
	components: {
		wsMatchItem
	},
	data() {
		return {
			// query: {
			// 	type: 1
			// }
		};
	},
	computed: {
		lives() {
			return this.$store.state.lives.map(this.makeMatchItem)
		}
	},
	created() {
		const to = this.$route;
		Object.assign(this.query, to.params, to.query);
		// this.$loading();
		// this.getLives();
	},
	beforeRouteUpdate(to, from, next) {
		this.query = Object.assign({}, to.params, to.query);
		console.info('to', to.query);
		// this.getLives();
		next();
	},
	methods: {
		async getLives() {
			const { type, league_id } = this.query;
			// console.info()
			const response = await this.$api.get(`/web/live_lists/${type}`, {
				params: { league_id }
			});
			if (response) {
				this.lists = response.data.data.map(this.makeMatchItem);
			}
			this.$nextTick(() => {
				this.$loadend();
			});
		}
	}
};
</script>

<style lang="css">
.page-lives {
	min-height: calc(100vh - 260px);
}
</style>
