const Storage = (function storage() {
	const namespace = "storage_4ezb";
	const storage = window.localStorage;
	if (!window.localStorage) {
		return false;
	}

	const set = function (key, value) {
		//存储
		let res = storage.getItem(namespace);
		if (!res) {
			this.init();
			res = storage.getItem(namespace);
		}
		res = JSON.parse(res);
		res.data[key] = value;
		storage.setItem(namespace, JSON.stringify(res));
		return res.data;

	};

	const get = function (key) {
		//读取
		let res = storage.getItem(namespace);
		if (!res) {
			return false;
		}
		res = JSON.parse(res);

		return res.data[key];
	};

	const remove = function (key) {
		//读取
		let res = storage.getItem(namespace);
		if (!res) {
			return false;
		}
		res = JSON.parse(res);
		delete res.data[key];
		storage.setItem(namespace, JSON.stringify(res));
		return res.data;
	};

	const clear = function () {
		//清除对象
		storage.removeItem(namespace);
	};

	const init = function () {
		storage.setItem(namespace, JSON.stringify({
			data: {}
		}));
	};

	return {
		set,
		get,
		remove,
		init,
		clear
	};

})();

export default Storage;
